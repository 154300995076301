
import React, { useState } from 'react';
import './Products.css';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

const Products = () => {

  const { language } = useLanguage();  // Get the current language from context
  const translations = language === 'en' ? en.services : lt.services;
  const translationsEmail = language === 'en' ? en.services.email : lt.services.email;

  const templateProducts = [
    {
      id: 1,
      name: translations.name11,
      price: "350-800",
      description: translations.description11,
      details: translations.details11,
      demo: "https://template-demo-link.com", // Link to template demo
      technologies: ["HTML", "CSS", "Bootstrap"],
      images: ["/AI_designs_preview/Template-Based/Business1.jpg", "/AI_designs_preview/Template-Based/Business2.jpg"],
    },
    {
      id: 2,
      name: translations.name12,
      price: "500-1000",
      description: translations.description12,
      details: translations.details12,
      demo: "https://portfolio-template-demo.com",
      technologies: ["React", "Bootstrap"],
      images: ["/AI_designs_preview/Template-Based/Portfolio1.jpg", "/AI_designs_preview/Template-Based/Portfolio2.jpg", "/AI_designs_preview/Template-Based/Portfolio3.jpg"],
    },
    {
      id: 3,
      name: translations.name13,
      price: "500-1500",
      description: translations.description13,
      details: translations.details13,
      demo: "https://portfolio-template-demo.com",
      technologies: ["React", "Bootstrap"],
      images: ["/AI_designs_preview/Template-Based/E-Commerce1.jpg", "/AI_designs_preview/Template-Based/E-Commerce2.jpg"],
    },
    {
      id: 4,
      name: translations.name14,
      price: "Contact",
      description: translations.description14,
      details: translations.details14,
      demo: "https://portfolio-template-demo.com",
      technologies: ["React", "Bootstrap"],
      images: ["/AI_designs_preview/Other/Other-Ideas.jpg"],
    }
    // ...other template products
  ];

    const products = [
      {
        id: 1,
        name: translations.name1,
        price: '800 - 2,500',
        description: translations.description1,
        details: translations.details1,
        technologies: ['React', 'CSS', 'HTML', 'Email API'],
        images: [
          '/AI_designs_preview/Company-Landing-Page/Hats-Company1.jpg',
          '/AI_designs_preview/Company-Landing-Page/Hats-Company2.jpg',
          '/AI_designs_preview/Company-Landing-Page/Games-Company.jpg',
          '/AI_designs_preview/Company-Landing-Page/Business-Company.jpg',
          '/AI_designs_preview/Company-Landing-Page/Yacht-Rent-Company1.jpg',
          '/AI_designs_preview/Company-Landing-Page/Yacht-Rent-Company2.jpg',
          '/AI_designs_preview/Company-Landing-Page/Yacht-Rent-Company3.jpg',
          '/AI_designs_preview/Company-Landing-Page/Real-Estate-Company.jpg'
        ]
      },
      {
        id: 2,
        name: translations.name2,
        price: '1,200 - 4,500',
        description: translations.description2,
        details: translations.details2,
        technologies: ['React', 'Node.js', 'MongoDB', 'Payment API'],
        images: [
          '/AI_designs_preview/E-Commerce/e-commerce-clothing-shop2.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-clothing-shop1.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-computer-shop3.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-computer-shop4.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-fishing-equipment-shop5.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-fishing-equipment-shop6.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-food-store7.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-motorbikes-shop10.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-motorbikes-shop9.jpg',
          '/AI_designs_preview/E-Commerce/e-commerce-skiing-equipment-shop8.jpg'
        ]
      },
      {
        id: 3,
        name: translations.name3,
        price: '1,200 - 3,000',
        description: translations.description3,
        details: translations.details3,
        technologies: ['React', 'Node.js', 'MongoDB'],
        images: [
          '/AI_designs_preview/Blog-Platform/Basketball-Blog-Platform2.jpg',
          '/AI_designs_preview/Blog-Platform/Basketball-Blog-Platform1.jpg',
          '/AI_designs_preview/Blog-Platform/Fitness-Blog-Platform1.jpg',
          '/AI_designs_preview/Blog-Platform/Fitness-Blog-Platform2.jpg',
          '/AI_designs_preview/Blog-Platform/Soccer-Blog-Platform1.jpg',
          '/AI_designs_preview/Blog-Platform/Soccer-Blog-Platform2.jpg'
        ]
      },
      {
        id: 4,
        name: translations.name4,
        price: '2,500 - 6,000',
        description: translations.description4,
        details: translations.details4,
        technologies: ['React', 'Node.js', 'MongoDB', 'Socket.io'],
        images: [
          '/AI_designs_preview/Community-Forum-Thread/Sim-Racing-Forum1.jpg',
          '/AI_designs_preview/Community-Forum-Thread/Sim-Racing-Forum2.jpg',
          '/AI_designs_preview/Community-Forum-Thread/Sim-Racing-Forum3.jpg',
          '/AI_designs_preview/Community-Forum-Thread/Gamers-Forum.jpg',
          '/AI_designs_preview/Community-Forum-Thread/Classic-Cars-Forum1.jpg',
          '/AI_designs_preview/Community-Forum-Thread/Classic-Cars-Forum2.jpg'
        ]
      },
      {
        id: 5,
        name: translations.name5,
        price: '2,500 - 6,000',
        description: translations.description5,
        details: translations.details5,
        technologies: ['React', 'Node.js', 'MongoDB'],
        images: [
          '/AI_designs_preview/News-Platform/News-Platform1.jpg',
          '/AI_designs_preview/News-Platform/News-Platform2.jpg',
          '/AI_designs_preview/News-Platform/News-Platform3.jpg'
        ]
      },
      {
        id: 6,
        name: translations.name6,
        price: '600 - 1,500',
        description: translations.description6,
        details: translations.details6,
        technologies: ['React', 'CSS', 'HTML', 'Email API'],
        images: [
          '/AI_designs_preview/Portfolio-Website/Portfolio1.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio2.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio3.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio4.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio5.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio6.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio7.jpg',
          '/AI_designs_preview/Portfolio-Website/Portfolio8.jpg'
        ]
      },
      {
        id: 7,
        name: translations.name7,
        price: '2,000 - 4,500',
        description: translations.description7,
        details: translations.details7,
        technologies: ['React', 'Node.js', 'MongoDB', 'Email API'],
        images: [
          '/AI_designs_preview/Booking-System/Booking-Flight1.jpg',
          '/AI_designs_preview/Booking-System/Booking-Flight2.jpg',
          '/AI_designs_preview/Booking-System/Booking-Flight3.jpg',
          '/AI_designs_preview/Booking-System/Booking-Hotel1.jpg',
          '/AI_designs_preview/Booking-System/Booking-Hotel2.jpg',
          '/AI_designs_preview/Booking-System/Booking-Car1.jpg',
          '/AI_designs_preview/Booking-System/Booking-Car2.jpg'
        ]
      },
      {
        id: 8,
        name: translations.name8,
        price: '5,000 - 12,000',
        description: translations.description8,
        details: translations.details8,
        technologies: ['React', 'Node.js', 'MongoDB', 'Socket.io'],
        images: [
          '/AI_designs_preview/Social-Network-Platform/Social-Network-Platform1.jpg',
          '/AI_designs_preview/Social-Network-Platform/Social-Network-Platform2.jpg',
          '/AI_designs_preview/Social-Network-Platform/Social-Network-Platform3.jpg',
          '/AI_designs_preview/Social-Network-Platform/Social-Network-Platform4.jpg'
        ]
      },
      {
        id: 9,
        name: translations.name9,
        price: '4,000 - 10,000',
        description: translations.description9,
        details: translations.details9,
        technologies: ['React', 'Node.js', 'MongoDB', 'AWS/S3'],
        images: [
          '/AI_designs_preview/Learning-System-(LMS)/LMS1.jpg',
          '/AI_designs_preview/Learning-System-(LMS)/LMS2.jpg',
          '/AI_designs_preview/Learning-System-(LMS)/LMS3.jpg',
          '/AI_designs_preview/Learning-System-(LMS)/LMS4.jpg'
        ]
      },
      {
        id: 10,
        name: translations.name10,
        price: 'Contact',
        description: translations.description10,
        details: translations.details10,
        technologies: ['React', 'Node.js', 'MongoDB', 'Custom Tech Stack'],
        images: [
          '/AI_designs_preview/Other/Other-Ideas.jpg',
        ]
      }
    ];

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleProductClick = (product) => {
    // Toggle the clicked product
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setCurrentImageIndex(0);
  };

    const handleNextImage = () => {
      if (selectedProduct && currentImageIndex < selectedProduct.images.length - 1) {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    };
  
    const handlePrevImage = () => {
      if (selectedProduct && currentImageIndex > 0) {
        setCurrentImageIndex(currentImageIndex - 1);
      }
    };

const handleEmailClick = (productname) => {
const email = "karolisweb@karolisweb.lt"; // Replace with your email
const subject = "Web Development: " + productname; // Customize the subject   
const body = 
`${translationsEmail.greeting}

${translationsEmail.introduction}

${translationsEmail.type} “${productname}”

${translationsEmail.questions.join("")}

`;
 // Customize the body
      
      // Use the mailto protocol with the email, subject, and body filled
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      
      // Trigger the email client to open with pre-filled content
      window.location.href = mailtoLink;
    };

  return (

    <main className='products-page'>
      <div className='leaf-section-top'>
        <div className="leaf-left-1-top"></div>
        <h1 className='navbar-header-services'>Services</h1>
        <div className="leaf-right-1-top"></div>
      </div>

      {/* Template-Based Websites Section */}

      <section>
        <div className='template-header-div'>
          <h1 className='template-header'>{translations["template-header-text"]}</h1>
        </div>
        <div className="template-separator-h1"></div>
        <div className='main-section'>
          {/* <div className="template-separator-v1"></div> */}
          <div className="template-products">
            <div className="template-product-grid">
              {templateProducts.map((product) => (
                <div
                  key={product.id}
                  className="product-card-template"
                  onClick={() => handleProductClick(product)}
                >
                  <div className="product-name-div">
                    <h2 className="product-name">{product.name}</h2>
                  </div>
                  <p className="product-description">{product.description}</p>
                  <p className="product-price">
                    <strong>{translations["price-text"]} {product.price}€</strong>
                  </p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const userConfirmed = window.confirm("This will open your email client. Do you want to proceed?");
                      if (userConfirmed) {
                        handleEmailClick(product.name);
                      }
                    }}
                    className="contact-btn"
                  >
                    {translations["contact-button-text"]}
                  </button>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="separator-v2"></div> */}
        </div>
        <div className="template-separator-h2"></div>
      </section>

      {/* Custom Websites Section */}
      <div className='custom-header-div'>       
        <h1 className='custom-header'>{translations["custom-header-text"]}</h1>
      </div>         
      <div className="separator-h1"></div>
      <div className='main-section'>
        <div className="separator-v1"></div>
        <div className="products">
          <div className="product-grid">
            {products.map(product => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => handleProductClick(product)}
              >
                <div className='product-name-div'>
                  {/* <div className='product-name-triangle-styling' /> */}
                  <h2 className='product-name'>{product.name}</h2>
                </div>
                <p className='product-description'>{product.description}</p>
                <p className='product-price'><strong>{translations['price-text']} {product.price}€</strong></p>
                <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent other events from triggering

                      // Display the confirmation dialog
                      const userConfirmed = window.confirm("This will open your email client. Do you want to proceed?");
                      
                      if (userConfirmed) {
                        handleEmailClick(product.name); // Call your email handler if the user confirms
                      }
                    }}
                    className='contact-btn'
                  >
                    {translations['contact-button-text']}
              </button>
              </div>
            ))}
          </div>
        </div>
        <div className="separator-v2"></div>
      </div>
      <div className="separator-h2"></div>





      {/* Modal for Product Details */}
      {selectedProduct && (
        <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>

            
            <div className="image-gallery">
              <button className="prev-button" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
                ←
              </button>
              <img
                src={selectedProduct.images[currentImageIndex]}
                alt={selectedProduct.name}
                className="product-image"
              />
              <button className="next-button" onClick={handleNextImage} disabled={currentImageIndex === selectedProduct.images.length - 1}>
                →
              </button>
            </div>

            
            <div className="separator-modal-content"></div>

            
            <div className='modal-right-side'>
              <button onClick={closeModal} className="close-button"><img src="/modal_images/close-button-icon-x-symbol-gimp.png" width="25" height="25" alt="Close" /></button>
              <h2 className='product-name-modal'>{selectedProduct.name}</h2>
              <p className='product-description-modal'>{selectedProduct.description}</p>
              <div className="separator-split-modal-1"></div>
              <p className='product-details-modal'>{selectedProduct.details}</p>
              <div className="separator-split-modal-2"></div>
              
              <div className='modal-last-section'>
                <div className='modal-price-section'>
                  <p className="product-price-modal">{translations.modal['price-text']} {selectedProduct.price}€</p>
                  <button
                      className="contact-btn-modal"
                      onClick={() => {
                        
                        const userConfirmed = window.confirm("This will open your email client. Do you want to proceed?");
                        
                        if (userConfirmed) {
                          handleEmailClick(selectedProduct.name); 
                        }
                      }}
                  >
                    <strong>{translations.modal['contact-button-text']}</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </main>
  );
};

export default Products;
