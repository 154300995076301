import React, { useEffect } from 'react';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import './LanguageSwitch.css';

const LanguageSwitch = () => {
    const { language, switchLanguage } = useLanguage();  // Get language state from context

    // Load the language from localStorage if it exists
    useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== language) {
      switchLanguage(savedLanguage);
    }
    // The empty array ensures it still only runs once
  },[]);

  // Store the language in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);
  
    return (
      <div className="language-switch">
        <button
          className={`toggle-btn-en ${language === 'en' ? 'active' : ''}`}
          onClick={() => switchLanguage('en')}
          disabled={language === 'en'}
        >
          EN
        </button>
        <button
          className={`toggle-btn-lt ${language === 'lt' ? 'active' : ''}`}
          onClick={() => switchLanguage('lt')}
          disabled={language === 'lt'}
        >
          LT
        </button>
      </div>
    );
  };
  
  export default LanguageSwitch;