import './AboutUs.css';
import CONTACTcomp from '../../components/CONTACTcomp/CONTACTcomp';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

const AboutUs = () => {

  const { language } = useLanguage();  // Get the current language from context
  const navbarHeader = language === 'en' ? en.navbar : lt.navbar;

  return (
    <main className='contacts-page'>
      <div className='contacts-leaf-section-top'>
        <div className="contacts-leaf-left-1-top"></div>
        <h1 className='contacts-navbar-header'>Contacts</h1>
        <div className="contacts-leaf-right-1-top"></div>
      </div>
      <div className="content1">
        <CONTACTcomp/>
      </div>   
    </main>
  );
}

export default AboutUs;