import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import Products from './pages/products/Products';
import FAQ from './pages/FAQ/FAQ'
import AboutUs from './pages/about/AboutUs';
import { LanguageProvider } from './hooks/LanguageContext/LanguageContext';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {

  return (
    <Router>
      <div className="App">
        <LanguageProvider>
          <Navbar/>
            <Switch>
              <Route exact path="/">
                <Home/>
              </Route>
              <Route exact path="/Services">
                <Products/>
              </Route>
              <Route exact path="/FAQ">
                <FAQ/>
              </Route>
              <Route exact path="/Contacts">
                <AboutUs/>
              </Route>
            </Switch>
        </LanguageProvider>
      </div>
    </Router>
  );
}

export default App;
