import React, { useState } from 'react';
import './FAQcomp.css'; // Optional: For custom styling
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

const FAQcomp = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const { language } = useLanguage();  // Get the current language from context
  const translations = language === 'en' ? en['faq-comp']: lt['faq-comp'];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: translations.question1,
      answer: translations.answer1
    },
    {
      question: translations.question2,
      answer: translations.answer2
    },
    {
      question: translations.question3,
      answer: translations.answer3
    },
    {
      question: translations.question4,
      answer: translations.answer4
    },
    {
      question: translations.question5,
      answer: translations.answer5
    },
    {
      question: translations.question6,
      answer: translations.answer6
    },
    {
      question: translations.question7,
      answer:  translations.answer7
    },
    {
      question: translations.question8,
      answer: translations.answer8
    },
    {
      question: translations.question9,
      answer: translations.answer9
    },
    {
      question: translations.question10,
      answer: translations.answer10
    },
    {
      question: translations.question11,
      answer: translations.answer11
    }
  ];

  return (
    <div className="faq-container">
      <h1 className='faq-header'>{translations.header}</h1>
      {faqData.map((faq, index) => (
        <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}  onClick={() => toggleFAQ(index)}>
          <div className="faq-question">
            {faq.question}
            <span>{activeIndex === index ? '-' : '+'}</span>
          </div>
          {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQcomp;