import React from 'react';
import './Home.css';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

const Home = () => {

  const { language } = useLanguage();  // Get the current language from context
  const translations = language === 'en' ? en.homepage : lt.homepage;
  const navbarHeader = language === 'en' ? en.navbar : lt.navbar;
    // Scroll to the section with smooth scrolling
    const scrollToSection = () => {
      const targetSection = document.getElementById('target-section');
      
      // Use window.scrollTo for smoother, more optimized scrolling
      window.scrollTo({
        top: targetSection.offsetTop, // Scroll to the top position of the section
        behavior: 'smooth' // Enables smooth scrolling
      });
    };

  return (
    <main className="home-page">
      <div className="home-leaf-section-top">
        <div className="home-leaf-left-top"></div>
        <h1 className='navbar-header-home'>Home</h1>
        <div className="home-leaf-right-top"></div>
      </div>
      <section className="section1">
        <div className="left-side">
          <div className="header1">
              <h1><b>{translations.header1} </b></h1>
          </div>
          <div className="paragraph1">
              <p><b>{translations.paragraph1}</b></p>
          </div>
          <div className="paragraph2">
              <p>{translations.paragraph2}</p>
          </div>
          <div className='scroll-down-div' onClick={scrollToSection} >
            <div className='scroll-down'></div>
          </div>
        </div>
        <div className="right-side">
          <div className="image-container1"></div>
        </div>
      </section>
      <div className="home-leaf-section1">
        <div className="home-leaf-left-1"></div>
        <div className="home-leaf-right-1"></div>
      </div>
      <section id='target-section' className='section2'>
        <div className='section2-div1'>
          <h1 className='header1-s2'>{translations['section2-header1']}</h1>
          <div className="horizontal-line"></div>
          <p className='paragraph1-s2'>{translations['section2-paragraph1']}</p>
          <p className='paragraph2-s2'><strong>•</strong> {translations['section2-paragraph2']}</p>
          <p className='paragraph3-s2'><strong>•</strong> {translations['section2-paragraph3']}</p>
        </div>
        <div className='section2-div2'>
          <h1 className='header2-s2'>{translations['section2-header2']}</h1>
          <div className="horizontal-line"></div>
          <p className='paragraph2-s2'>{translations['section2-paragraph4']}</p>
          <p className='paragraph3-s2'>{translations['section2-paragraph5']}</p>
        </div>
      </section>
      <div className="home-leaf-section2">
        <div className="home-leaf-left-1"></div>
        <div className="home-leaf-right-1"></div>
      </div>
      <section className='section3'>
        <div className='section3-div1'>
          <h1 className='header1-s2'>{translations['section3-header1']}</h1>
          <div className="horizontal-line"></div>
          <p className='paragraph2-s2'>{translations['section3-paragraph1']}</p>
          <p className='paragraph3-s2'>{translations['section3-paragraph2']}</p>
        </div>
        <div className="image-container2"></div>
      </section>
      {/* <div className="home-leaf-section3">
        <div className="home-leaf-left-1"></div>
        <div className="home-leaf-right-1"></div>
      </div> */}
    </main>
  );
}

export default Home;