import React from 'react';
import FAQcomp from '../../components/FAQcomp/FAQcomp';
import './FAQ.css';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

const FAQ = () => {

  const { language } = useLanguage();  // Get the current language from context
  const navbarHeader = language === 'en' ? en.navbar : lt.navbar;

  return (
    <main className='FAQ-page'>
      <div className='FAQ-leaf-section-top'>
        <div className="FAQ-leaf-left-1-top"></div>
        <h1 className='navbar-header'>Frequently Asked Questions</h1>
        <div className="FAQ-leaf-right-1-top"></div>
      </div>
      <div className="content1">
        <FAQcomp />
      </div>   
    </main>
  );
}

export default FAQ;