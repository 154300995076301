import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';
import './Navbar.css';

const Navbar = () => {

  const { language, switchLanguage } = useLanguage();  // Get the current language from context
  const translations = language === 'en' ? en.navbar : lt.navbar;

  const [isChecked, setIsChecked] = useState(false); // State to manage checkbox status

  // Handle checkbox toggle for opening/closing the menu
  const handleCheckboxToggle = () => {
    setIsChecked(!isChecked);
  };

  // Close the menu when a link is clicked
  const closeMenu = () => {
    setIsChecked(false); // Uncheck the checkbox when a menu item is clicked
  };

  return (
    <nav>
      <div className="navbar">
        <div className="container nav-container">
          {/* Checkbox to control the hamburger menu */}
          <input
            className="checkbox"
            type="checkbox"
            name="menu-checkbox"
            id="menu-checkbox"
            checked={isChecked}  // Controlled by React state
            onChange={handleCheckboxToggle} // Toggle state on checkbox click
          />
          <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
          </div>  
          <div className="menu-items">
            {/* Close the menu when any menu item is clicked */}
            <li><Link to="/" onClick={closeMenu}>{translations['home-header']}</Link></li>
            <li><Link to="/Services" onClick={closeMenu}>{translations['services-header']}</Link></li>
            <li><Link to="/FAQ" onClick={closeMenu}>{translations['faq-header-main']}</Link></li>
            <li><Link to="/Contacts" onClick={closeMenu}>{translations['contacts-header']}</Link></li>
            <li><LanguageSwitch language={language} switchLanguage={switchLanguage} /></li>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;