import React from 'react';
import './CONTACTcomp.css';
import { useLanguage } from '../../hooks/LanguageContext/LanguageContext';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

const CONTACTcomp = () => {

  const { language } = useLanguage();  // Get the current language from context
  const translations = language === 'en' ? en.contactPage : lt.contactPage;
  
  return (
    <div className="contact-page-container">
      <h1>{translations.header1}</h1>
      <p>
         {translations.paragraph1}
      </p>

      <div className="separator-horizontal-1"></div>

      <h2>{translations.header2}</h2>
      <p>
        {translations.paragraph2}
      </p>

      <ul>
        <li>
          <strong>Email:</strong> <a href="mailto:karolisweb@karolisweb.lt">karolisweb@karolisweb.lt</a>
        </li>
        <li>
          <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/karolis-kančys-42603525b" target="_blank" rel="noopener noreferrer">Connect with me on LinkedIn</a>
        </li>
        <li>
          <strong>{translations.phone}</strong> <a href='tel:+37067138570'>[LT] +37067138570</a>
        </li>
      </ul>

      <div className="separator-horizontal-1"></div>

      <h2>{translations.header3}</h2>
      <p>
        {translations.paragraph3}
      </p>
      <ul>
        <li>{translations.ListItem1}</li>
        <li>{translations.ListItem2}</li>
        <li>{translations.ListItem3}</li>
        <li>{translations.ListItem4}</li>
      </ul>

      <p className='last-paragraph'>
        {translations.paragraph4}
      </p>
    </div>
  );
};

export default CONTACTcomp;